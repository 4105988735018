<template>
  <div id="app">
    <ResultPage />
  </div>
</template>

<script>
import ResultPage from './components/pages/ResultPage';
import utils from './lib/utils'

export default {
  name: 'ResultPageApp',

  components: {
    ResultPage
  },

  beforeCreate() {
    if (!utils.hasProperty(window.translations, 'dict')) {
      this.$store
        .dispatch('translations/fetchAll', window.translations)
        .then((dict) => {
          console.log('dict fetched');
          console.log(dict);
          this.$store.state.translations.dict = dict;
        });
    } else {
      this.$store.commit('translations/setDict', window.translations.dict);
    }
  }
};
</script>

<style lang="scss">
@import 'scss/app';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
