<template>
  <div class="cp-outer">
    <div class="cp-inner">
      <div class="wrapper">
        <PlainHeader></PlainHeader>
        <ResultContainer headerImage="/assets/img/page-not-found_header.png">
          <h2>{{ errorTitle }}</h2>
          <h3 v-if="errorSubtitle" v-html="errorSubtitle"></h3>
          <p>{{ errorMessage }}</p>
          <footer>
            <CpButtonAnchor
              color="blue"
              size="large"
              weight="bold"
              href="/"
            >Zurück zur Startseite</CpButtonAnchor>
          </footer>
        </ResultContainer>
        <MainFooter></MainFooter>
      </div>
    </div>
  </div>
</template>

<script>
import PlainHeader from '../templates/PlainHeader';
import ResultContainer from '../templates/ResultContainer';
import MainFooter from '../templates/MainFooter';
import CpButtonAnchor from '../atoms/CpButton/CpButtonAnchor';

export default {
  name: 'ResultPage',

  components: {
    PlainHeader,
    ResultContainer,
    MainFooter,
    CpButtonAnchor
  },

  computed: {
    errorTitle() {
      return window.RequestResult.title;
    },
    errorSubtitle() {
      return window.RequestResult.subtitle === '' ? 0 : window.RequestResult.subtitle;
    },
    errorMessage() {
      return window.RequestResult.message;
    }
  }
};
</script>
