import Vue from 'vue';
import Vuex from 'vuex';
import Inview from 'vueinview';

import App from './ResultPageApp';
import store from './store';
import globalMixins from './lib/mixins/global-mixins';

Vue.use(Vuex);
Vue.use(Inview);
Vue.mixin(globalMixins);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  render: (h) => h(App)
});
/* eslint-enable no-new */
